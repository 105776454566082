import $ from 'jquery'

$(document).ready( initATI )

if (window.acf) {
  window.acf.addAction('render_block_preview/type=animated-text-image', initATI)
}

function blockReady() {
  document.dispatchEvent(new CustomEvent('animate-text-image-ready'))
  $('.ati h2').addClass('loaded')
}
function initATI() {
  if ($('.editor-styles-wrapper').length) return false;

  const text = $('.ati h2').text().trim();
  const quoteRegex = /["‘’“”][^"‘’“”]+["‘’“”]/g;

  // Arrays to store words and quoted text with their positions
  const wordPositions = [];
  const quotedText = [];
  
  // Extract words and quoted text with positions
  text.replace(/\S+/g, (match, offset) => wordPositions.push({ word: match, position: offset }));
  text.replace(quoteRegex, (match, offset) => quotedText.push({
    text: match.replace(/["‘’“”]/g, '').trim(),
    start: offset,
    end: offset + match.length
  }));

  // Reconstruct words array, avoiding overlap with quoted text
  let reconstructedWords = [];
  let quoteIndex = 0;

  wordPositions.forEach(({ word, position }) => {
    while (quoteIndex < quotedText.length && quotedText[quoteIndex].end <= position) {
      reconstructedWords.push(quotedText[quoteIndex].text);
      quoteIndex++;
    }
    if (!quotedText.some(qt => position >= qt.start && position < qt.end)) {
      reconstructedWords.push(word);
    }
  });

  while (quoteIndex < quotedText.length) {
    reconstructedWords.push(quotedText[quoteIndex].text);
    quoteIndex++;
  }

  // Update DOM
  $('.ati h2').html(reconstructedWords.map(word => `<span class="js-fade-in-out">${word}</span>`).join(''));
  blockReady();

  // Aria label
  let blockP = $('.ati p').text();
  let blockH2Spans = [];
  $(".ati h2 >span").each(function () {
    blockH2Spans.push($(this).text());
  });
  let fullText = blockP + ' ' + (blockH2Spans.join(", "));
  $('.mg-block--ati').attr('aria-label', fullText);
  

  // Event handler for play/pause button
  $('.js-animate-text-pause').on("click", function () {
    const btn = $(this);
    const btntext = btn.find('.u-sr-only');
    const isPlaying = btn.data('play');
    const btnIcon = btn.find('svg');
    const pauseIcon = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="12" fill="#FAA530"/><path fill-rule="evenodd" clip-rule="evenodd" d="M8.80005 7.74683V16.2802C8.80005 16.869 9.2779 17.3469 9.8667 17.3469C10.4555 17.3469 10.9334 16.869 10.9334 16.2802V7.74683C10.9334 7.15803 10.4555 6.68018 9.8667 6.68018C9.2779 6.68018 8.80005 7.15803 8.80005 7.74683ZM13.0667 7.74683V16.2802C13.0667 16.869 13.5446 17.3469 14.1334 17.3469C14.7222 17.3469 15.2 16.869 15.2 16.2802V7.74683C15.2 7.15803 14.7222 6.68018 14.1334 6.68018C13.5446 6.68018 13.0667 7.15803 13.0667 7.74683Z" fill="#1B1815"/></svg>';
    const playIcon = '<svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="15" fill="#FAA530"/><path d="M10 19.723C10.0274 20.964 11.254 21.7156 12.3289 21.1247L20.9714 16.0422C21.4421 15.765 21.7615 15.2571 21.7615 14.662C21.7615 14.0668 21.4421 13.5589 20.9714 13.2817L12.3289 8.20634C11.254 7.61538 10.0274 8.35974 10 9.60099V19.723Z" fill="#1B1815"/></svg>';
    
    document.dispatchEvent(new CustomEvent(isPlaying ? 'animate-text-image-paused' : 'animate-text-image-play'));
    btntext.text(isPlaying ? "Play video" : "Pause video");
    btnIcon.replaceWith(isPlaying ? playIcon : pauseIcon);
    
    btn.data('play', !isPlaying);
  });
}